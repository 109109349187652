import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import bgImage from '../assets/Untitled design (54).png'
import logoImage from '../assets/Untitled design (52).png'
import modalFrame from '../assets/Untitled design (39).png'
import { motion } from 'framer-motion';
import { useRef } from "react";
import { MainContext } from "../App";

const HomeScreen = () => {

  const { deviceType } = useContext(MainContext)
  console.log('devicetype home', deviceType)
  const aspectRatio = 1.77
  const width = deviceType === 'mobile' ? '515' : '600'
  const height = width / aspectRatio




  const [isModalOpen, setIsModalOpen] = useState(false);
  const [frameHeight, setFrameHeight] = useState(0);
  const [frameWidth, setFrameWidth] = useState(300);
  const [buttonClicked, setButtonClicked] = useState('')


  const frameRef = useRef(null)




  useEffect(() => {
    if (isModalOpen && frameRef) {
      console.log('initializing if statement...')
      if (frameRef.current) {
        let width = frameRef?.current?.clientWidth
        let height = width / aspectRatio
        setFrameHeight(height)
        console.log('initial frameHeight =>', height)
      }
    }
    console.log('frameRef =>', frameRef)
  }, [frameRef, isModalOpen,])

  useEffect(() => {
    console.log('frameHeight', frameHeight)
  }, [frameHeight, frameRef.current, buttonClicked])


  const modalText = [

    {
      text: 'Youtube',
      bodyText: `Want to catch the next big crypto opportunity before it blows up? Subscribe to my YouTube channel for insider tips, in-depth analysis, and the strategies you need to start winning big in the memecoin game!`

    },
    {
      text: 'Telegram',
      bodyText1: `Don’t miss out on access to free insider wallets and exclusive insights—join my Telegram channel, ‘β.toshi 100x HQ,’ and stay ahead of the memecoin game. Get the inside scoop on the hottest trends before anyone else!`,
    },
    {
      text: 'Photon',
      bodyText1: `Find the next 100x memecoin with Photon—the ultimate tool for spotting hidden gems before they explode. Don’t miss out on your chance to trade like a pro and maximize your gains! `,
    },
    {
      text: 'X',
      bodyText1: `Stay ahead of the crypto curve by following me on X! Get instant updates, hot memecoin picks, and exclusive insights to power up your trading! `,
    },
    {
      text: 'Solana Trading Bot',
      bodyText1: `Take your Solana trading to the next level with the Trojan Telegram bot—lightning-fast execution, advanced features, and effortless setup. Don’t miss out on maximizing your profits; get the Trojan bot today and dominate the market! `,
    },
    {
      text: 'GMGN.ai',
      bodyText1: `Unlock the power of insider trading data with GMGN.ai—your secret weapon for tracking top wallets and making smarter trades. Don’t trade blind; use GMGN.ai to gain the edge you need in the crypto market! `,
    },

  ]

  return (
    <Box sx={{ position: 'relative', overflowX: { xs: 'scroll', md: 'hidden' }, overflowY: 'hidden', height: 'inherit', width: { xs: 'fit-content', md: '100%' } }}>

      <img src={bgImage} alt="" style={{ height: '100%', width: deviceType === 'mobile' ? 'auto' : '100%', objectFit: 'cover', zIndex: 0, }} />

      {/* Coin Logo */}
      <div
        style={{
          width: 35,
          height: 35,
          // backgroundColor: 'lightgrey',
          borderRadius: '50%',
          position: 'absolute',
          top: deviceType === 'mobile' ? '4%' : '5%',
          left: deviceType === 'mobile' ? '7%' : '5%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <img src={logoImage} alt="" style={{ height: '100px', width: '100px' }} />
      </div>

      {/* Coin Text */}
      <div
        style={{
          width: 250,
          height: 65,
          // backgroundColor: 'lightgrey',
          // borderRadius: '50%',
          position: 'absolute',
          top: deviceType === 'mobile' ? '8%' : '10%',
          left: deviceType === 'mobile' ? '61%' : '18%',
          transform: 'translate(-50%, -50%)',
          // padding: '5%'
        }}
      >
        <Typography style={{ fontWeight: 'bold', fontSize: deviceType === 'mobile' ? '7cqw' : '2cqw', color: 'white', fontFamily: 'Bebas Neue, sans-serif' }}>β.toshi's 100X HQ</Typography>
      </div>
      {/* Youtube  Outer Dots  */}
      <motion.div
        style={{
          width: 20,
          height: 20,
          backgroundColor: '#D96CF099',
          borderRadius: '50%',
          position: 'absolute',
          top: deviceType === 'mobile' ? '33%' : '31%',
          left: deviceType === 'mobile' ? '41%' : '13%',
          transform: 'translate(-50%, -50%)'
        }}
        animate={{
          scale: [1, 1.5, 1],
          opacity: [1, 0]
        }}
        transition={{
          duration: 1,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
      </motion.div>

      {/* X  Outer Dots  */}
      <motion.div
        style={{
          width: 20,
          height: 20,
          backgroundColor: '#D96CF099',
          borderRadius: '50%',
          position: 'absolute',
          top: deviceType === 'mobile' ? '46%' : '42%',
          left: deviceType === 'mobile' ? '163.7%' : '51%',
          transform: 'translate(-50%, -50%)'
        }}
        animate={{
          scale: [1, 1.5, 1],
          opacity: [1, 0]
        }}
        transition={{
          duration: 1,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
      </motion.div>

      {/* Trojan Outer Dots  */}
      <motion.div
        style={{
          width: 20,
          height: 20,
          backgroundColor: '#D96CF099',
          borderRadius: '50%',
          position: 'absolute',
          top: deviceType === 'mobile' ? '14%' : '9%',
          left: deviceType === 'mobile' ? '186%' : '55.7%',
          transform: 'translate(-50%, -50%)'
        }}
        animate={{
          scale: [1, 1.5, 1],
          opacity: [1, 0]
        }}
        transition={{
          duration: 1,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
      </motion.div>

      {/* GMGN Outer Dots  */}
      <motion.div
        style={{
          width: 20,
          height: 20,
          backgroundColor: '#D96CF099',
          borderRadius: '50%',
          position: 'absolute',
          top: '38%',
          left: deviceType === 'mobile' ? '251%' : '82%',
          transform: 'translate(-50%, -50%)'
        }}
        animate={{
          scale: [1, 1.5, 1],
          opacity: [1, 0]
        }}
        transition={{
          duration: 1,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
      </motion.div>

      {/*  Telegram Outer Dot  */}
      <motion.div
        style={{
          width: 20,
          height: 20,
          backgroundColor: '#D96CF099',
          borderRadius: '50%',
          position: 'absolute',
          top: deviceType === 'mobile' ? '27%' : '24%',
          left: deviceType === 'mobile' ? '109%' : '31%',
          // transform: 'translate(-50%, -50%)'
        }}
        animate={{
          scale: [1, 1.5, 1],
          opacity: [1, 0]
        }}
        transition={{
          duration: 1,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
      </motion.div>

      {/* Photon Outer Dots  */}
      <motion.div
        style={{
          width: 20,
          height: 20,
          backgroundColor: '#D96CF099',
          borderRadius: '50%',
          position: 'absolute',
          top: '45%',
          left: deviceType === 'mobile' ? '59%' : '18%',
          // transform: 'translate(-50%, -50%)'
        }}
        animate={{
          scale: [1, 1.5, 1],
          opacity: [1, 0]
        }}
        transition={{
          duration: 1,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
      </motion.div>

      {/* Youtube  Inner Dots  */}
      <div
        onClick={() => { setIsModalOpen(true); setButtonClicked('Youtube') }}
        style={{
          width: 12,
          height: 12,
          backgroundColor: 'white',
          borderRadius: '50%',
          position: 'absolute',
          top: deviceType === 'mobile' ? '34.3%' : '32.1%',
          left: deviceType === 'mobile' ? '43.3%' : '13.6%',
          transform: 'translate(-50%, -50%)'
        }}
      ></div>

      {/*  Telegram Inner Dots  */}
      <div
        onClick={() => { setIsModalOpen(true); setButtonClicked('Telegram') }}
        style={{
          width: 12,
          height: 12,
          backgroundColor: 'white',
          borderRadius: '50%',
          position: 'absolute',
          top: deviceType === 'mobile' ? '27.5%' : '24.5%',
          left: deviceType === 'mobile' ? '109.7%' : '31.2%',
          // transform: 'translate(-50%, -50%)'
        }}
      ></div>

      {/* X Inner Dots  */}
      <div
        onClick={() => { setIsModalOpen(true); setButtonClicked('X') }}
        style={{
          width: 12,
          height: 12,
          backgroundColor: 'white',
          borderRadius: '50%',
          position: 'absolute',
          top: deviceType === 'mobile' ? '46.5%' : '42.5%',
          left: deviceType === 'mobile' ? '164.5%' : '51.2%',
          // transform: 'translate(-50%, -50%)'
        }}
      ></div>

      {/* Trojan Inner Dots  */}
      <div
        onClick={() => { setIsModalOpen(true); setButtonClicked('Trojan') }}
        style={{
          width: 12,
          height: 12,
          backgroundColor: 'white',
          borderRadius: '50%',
          position: 'absolute',
          top: deviceType === 'mobile' ? '14.5%' : '9.5%',
          left: deviceType === 'mobile' ? '187%' : '55.9%',
          // transform: 'translate(-50%, -50%)'
        }}
      ></div>

      {/* GMGN Inner Dots  */}
      <div
        onClick={() => { setIsModalOpen(true); setButtonClicked('GMGN') }}
        style={{
          width: 12,
          height: 12,
          backgroundColor: 'white',
          borderRadius: '50%',
          position: 'absolute',
          top: '38.5%',
          left: deviceType === 'mobile' ? '252.2%' : '82.2%',
          // transform: 'translate(-50%, -50%)'
        }}
      ></div>

      {/* Photon Inner Dots  */}
      <div
        onClick={() => { setIsModalOpen(true); setButtonClicked('Photon') }}
        style={{
          width: 12,
          height: 12,
          backgroundColor: 'white',
          borderRadius: '50%',
          position: 'absolute',
          top: '45.5%',
          left: deviceType === 'mobile' ? '60%' : '18.2%',
          // transform: 'translate(-50%, -50%)'
        }}
      ></div>

      {/* Youtube Text  */}
      <Typography style={{
        fontSize: 20, borderRadius: '50%',
        position: 'absolute',
        top: deviceType === 'mobile' ? '31%' : '29%',
        left: deviceType === 'mobile' ? '49%' : '15%',
        transform: 'translate(-50%, -50%)',
        fontWeight: 'bold',
        color: 'white',
        fontFamily: 'Bebas Neue, sans-serif',
        width: 100
      }}>
        Youtube
      </Typography>

      {/* Telegram Text  */}
      <Typography style={{
        fontSize: 20, borderRadius: '50%',
        position: 'absolute',
        top: deviceType === 'mobile' ? '22%' : '20%',
        left: deviceType === 'mobile' ? '105.2%' : '29.7%',
        // transform: 'translate(-50%, -50%)',
        fontWeight: 'bold',
        color: 'white',
        fontFamily: 'Bebas Neue, sans-serif',
        width: 200
      }}>
        Telegram
      </Typography>

      {/* X Text  */}
      <Typography style={{
        fontSize: 20, borderRadius: '50%',
        position: 'absolute',
        top: deviceType === 'mobile' ? '41%' : '38%',
        left: deviceType === 'mobile' ? '161%' : '50.5%',
        // transform: 'translate(-50%, -50%)',
        fontWeight: 'bold',
        color: 'white',
        fontFamily: 'Bebas Neue, sans-serif',
        width: 200
      }}>
        X.com
      </Typography>

      {/* Trojan Text  */}
      <Typography style={{
        fontSize: 20, borderRadius: '50%',
        position: 'absolute',
        top: deviceType === 'mobile' ? '10%' : '5%',
        left: deviceType === 'mobile' ? '181%' : '54%',
        // transform: 'translate(-50%, -50%)',
        fontWeight: 'bold',
        color: 'white',
        fontFamily: 'Bebas Neue, sans-serif',
        width: 200
      }}>
        Solana Bot
      </Typography>

      {/* GMGN Text  */}
      <Typography style={{
        fontSize: 20, borderRadius: '50%',
        position: 'absolute',
        top: '34%',
        left: deviceType === 'mobile' ? '249%' : '81%',
        // transform: 'translate(-50%, -50%)',
        fontWeight: 'bold',
        color: 'white',
        fontFamily: 'Bebas Neue, sans-serif',
        width: 200
      }}>
        GMGN.ai
      </Typography>

      {/* Photon Text  */}
      <Typography style={{
        fontSize: 20, borderRadius: '50%',
        position: 'absolute',
        top: deviceType === 'mobile' ? '41%' : '41%',
        left: deviceType === 'mobile' ? '57%' : '17.2%',
        // transform: 'translate(-50%, -50%)',
        fontWeight: 'bold',
        color: 'white',
        fontFamily: 'Bebas Neue, sans-serif',
        width: 200
      }}>
        Photon
      </Typography>

      <div style={{ position: 'sticky', bottom: 0, left: '4%', zIndex: 10, width: '100dvw', display: deviceType === 'mobile' ? 'flex' : 'none' }}>
        <motion.div
          initial={{ scale: 1 }}
          animate={{ scale: [1, 1.2, 1] }}
          transition={{ duration: 1, repeat: Infinity }}
        >
          <Typography style={{
            fontWeight: 'bold',
            color: 'white',
            fontFamily: 'Bebas Neue, sans-serif',
            fontSize: 30,
          }}>
            SLIDE {'>>'}
          </Typography>
        </motion.div>
      </div>

      {/* MODAL */}
      <Modal open={!!isModalOpen} onClose={() => setIsModalOpen(!isModalOpen)}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 'none',
          borderRadius: '10px',
          width: `${width}px`,
          height: `${height}px`
          // containerType: 'inline-size'
          // padding: '20px',
          // zIndex: 1000,
          // overflow: 'hidden',
          // overflowY: 'scroll',
        }}>
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            style={{ width: 'inherit', height: 'inherit', position: 'relative' }}
          >
            <div
              style={{
                height: deviceType === 'mobile' ? '55%' : '54%',
                width: '46%',
                // backgroundColor: '#D96CF099',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 0,
                containerType: 'inline-size',
                padding: '5%',
              }}
            >
              {
                buttonClicked === 'Youtube' ?
                  <>
                    <Typography style={{ color: 'white', fontSize: '12cqw', width: '100%', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif' }}>
                      {modalText[0].text}
                    </Typography>
                    <Typography style={{ color: 'white', fontSize: '4.3cqw', width: '100%', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif', }}>
                      {modalText[0].bodyText}
                    </Typography>
                    <Button variant="contained" size='large' onClick={() => { console.log('clicked'); window.open('https://www.youtube.com/@btoshi100x'); }} style={{
                      left: deviceType === 'mobile' ? '35%' : '37%', height: 20, top: '3%', width: 80, backgroundColor: '#D96CF0', zIndex: 2

                    }}><Typography style={{ color: 'white', fontSize: '4cqw', width: '100%', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif' }}>
                        SUBscribe
                      </Typography></Button>

                  </>

                  :

                  buttonClicked === 'Telegram' ?
                    <>
                      <Typography style={{ color: 'white', fontSize: '11cqw', width: '100%', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif' }}>
                        {modalText[1].text}
                      </Typography>
                      <Typography style={{ color: 'white', fontSize: '4.3cqw', width: '100%', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif' }}>
                        {modalText[1].bodyText1}
                      </Typography>
                      <Button variant="contained" size='large' onClick={() => { console.log('clicked'); window.open('https://t.co/MvDt0wdbgF'); }} style={{
                        left: deviceType === 'mobile' ? '35%' : '37%', height: 20, top: '3%', width: 80, backgroundColor: '#D96CF0', zIndex: 2

                      }}><Typography style={{ color: 'white', fontSize: '4cqw', width: '100%', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif' }}>
                          JOIN
                        </Typography></Button>


                    </>

                    :
                    buttonClicked === 'Photon' ?
                      <>
                        <Typography style={{ color: 'white', fontSize: '11cqw', width: '100%', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif' }}>
                          {modalText[2].text}
                        </Typography>
                        <Typography style={{ color: 'white', fontSize: '5cqw', width: '100%', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif' }}>
                          {modalText[2].bodyText1}
                        </Typography>
                        <Button variant="contained" size='large' onClick={() => { console.log('clicked'); window.open('https://photon-sol.tinyastro.io/@btoshi333'); }} style={{
                          left: deviceType === 'mobile' ? '35%' : '37%', height: 20, top: '3%', width: 80, backgroundColor: '#D96CF0', zIndex: 2

                        }}><Typography style={{ color: 'white', fontSize: '4cqw', width: '100%', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif' }}>
                            JOIN
                          </Typography></Button>

                      </>
                      :
                      buttonClicked === 'X' ?
                        <>
                          <Typography style={{ color: 'white', fontSize: '11cqw', width: '100%', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif' }}>
                            {modalText[3].text}
                          </Typography>
                          <Typography style={{ color: 'white', fontSize: '5cqw', width: '100%', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif' }}>
                            {modalText[3].bodyText1}
                          </Typography>
                          <Button variant="contained" size='large' onClick={() => { console.log('clicked'); window.open('https://x.com/btoshi333'); }} style={{
                            left: deviceType === 'mobile' ? '35%' : '37%', height: 20, top: '3%', width: 80, backgroundColor: '#D96CF0', zIndex: 2

                          }}><Typography style={{ color: 'white', fontSize: '3.5cqw', width: '100%', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif' }}>
                              Follow
                            </Typography></Button>

                        </>
                        :
                        buttonClicked === 'Trojan' ?
                          <>
                            <Typography style={{ color: 'white', fontSize: '11cqw', width: '100%', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif' }}>
                              {modalText[4].text}
                            </Typography>
                            <Typography style={{ color: 'white', fontSize: '4cqw', width: '100%', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif' }}>
                              {modalText[4].bodyText1}
                            </Typography>
                            <Button variant="contained" size='large' onClick={() => { console.log('clicked'); window.open('https://t.me/helenus_trojanbot?start=r-crayamink'); }} style={{
                              left: deviceType === 'mobile' ? '35%' : '37%', height: 20, top: '3%', width: 80, backgroundColor: '#D96CF0', zIndex: 2

                            }}><Typography style={{ color: 'white', fontSize: '3.5cqw', width: '100%', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif' }}>
                                Get
                              </Typography></Button>

                          </>
                          :
                          buttonClicked === 'GMGN' ?
                            <>
                              <Typography style={{ color: 'white', fontSize: '11cqw', width: '100%', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif' }}>
                                {modalText[5].text}
                              </Typography>
                              <Typography style={{ color: 'white', fontSize: '4cqw', width: '100%', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif' }}>
                                {modalText[5].bodyText1}
                              </Typography>
                              <Button variant="contained" size='large' onClick={() => { console.log('clicked'); window.open('https://gmgn.ai/trend/SNmflKs1Z?chain=sol'); }} style={{
                                left: deviceType === 'mobile' ? '35%' : '37%', height: 20, top: '3%', width: 80, backgroundColor: '#D96CF0', zIndex: 2

                              }}><Typography style={{ color: 'white', fontSize: '3.5cqw', width: '100%', textAlign: 'center', fontFamily: 'Bebas Neue, sans-serif' }}>
                                  Join
                                </Typography></Button>

                            </>
                            :
                            buttonClicked === ''
              }

            </div>
            <div
              style={{
                height: deviceType === 'mobile' ? '55%' : '54%',
                width: '46%',
                backgroundColor: '#D96CF099',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: -2,
                containerType: 'inline-size',
                padding: '5%',
              }}
            >

            </div>
            <img
              src={modalFrame}
              alt=""
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: -1,
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
            />
          </motion.div>

        </Box>
      </Modal>
    </Box>
  );
};

export default HomeScreen;
